import tw from 'twin.macro'
import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'

const Wrapper = tw.div`
  flex items-center justify-center flex-col h-screen
`

const Main = tw.div`
  p-6 bg-gray-100 rounded-lg shadow-2xl
`

const Heading = tw.h1`
  text-2xl text-gray-500 uppercase
`

const Text = tw.p`
  text-xl text-gray-700
`

const SignupWelcome = () => {
  return (
    <Layout>
      <Wrapper>
        <SEO title="Signup Welcome" />
        <Main>
          <Heading>Welcome to Privacy Self Defense</Heading>
          <Text>
            Checkout the resources at{' '}
            <a href="https://privacyselfdefense.com">privacyselfdefense.com</a>.
          </Text>
        </Main>
      </Wrapper>
    </Layout>
  )
}

export default SignupWelcome
